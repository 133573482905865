/* for the background color of the form and image */
.iconColor {
  width: 100%;
  height: 100%;
  font-size: large;
  color: var(--white);
}
.formPrimary {
  background-color: var(--background);
  width: 100vw;
  /* height: 100vh; */
}

.loginRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: var(--white);
}
.loginRow a {
  text-decoration: none;
  color: var(--brand);
}
.loginRow a:hover {
  color: var(--brandHover);
}
.signUpConditionsRow {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signUpConditionsRow a {
  color: var(--brand);
  text-decoration: none;
  padding: 10px;
}
.signUpConditionsRow a:hover {
  color: var(--brandHover);
  transition: all 0.5s ease-in-out;
  /* transform: scale(1.1); */
}
.signUpFormContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  align-items: center;
}
.formSignUpPrimary {
  width: 50%;
}

/* // for image and form to align flex */
.formSecondary {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/* // image  */
.formSecondary img {
  height: 60vh;
  width: 40vw;
  display: flex;
}
/* main form */

.form {
  width: 70%;
  height: auto;
  margin: 10% 15%;
  border: 1px solid var(--white);
  background-color: var(--white);
  padding: 20px;
}

/* //form blog is for big text area input like creatign blogs */
.formBlog {
  width: 50%;
  height: auto;
  margin: 50px 20% 0 20%;
  border: 1px solid var(--card);
  background-color: var(--card);
  padding: 20px;
}

/* // input of form */
.formSecondary input,
.formSecondary textarea,
.formSecondary select {
  padding: 11px 10px;
  border: none;
  background: #12161d;
  color: var(--white);
  
  /* // safari support */

  -webkit-appearance: initial;
}

.formSecondary textarea::placeholder,
.formSecondary input::placeholder {
  font-size: initial;
  color: var(--blueSilver) !important;
}
/* // focus of input */
.formSecondary input:focus,
.formSecondary textarea:focus {
  outline: none;
}
/* // icon and input together */

.formInput {
  width: 80%;
  background: var(--blueSilver);
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  align-items: center;
  /* padding-left: 15px; */
  text-align: center;
  border: 1px solid var(--cardStroke);
  border-radius: 4px;
  margin: 20px auto;
}

.profilePageInput {
  width: 80%;
  background: var(--blueSilver);
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  align-items: center;
  /* padding-left: 15px; */
  text-align: center;
  border: 1px solid var(--cardStroke);
  border-radius: 4px;
  margin: 15px auto;
}

/* // invalid form input */
.formInputInvalid {
  background-color: var(--red) !important;
  color: var(--white) !important;
  border: 1px solid var(--red) !important;
}
/* // focusing the whole icon and input when focused upon inpu */
.formInput:focus-within {
  background-color: var(--brand);
  border: 1px solid var(--brand);
}
.formInput {
  background-color: var(--brand);
  border: 1px solid var(--brand);
}

/* // h2 */
.formHeader {
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
}
.formHeader h2 {
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
}
/* Guest: Ανδρέας Χαραλάμπους, Ιδρυτής της Capsule Skateboards */
.formHeader::after {
  background-color: var(--brand);
}

/* // link textxx */
.linkText {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 80%;
  margin-top: 20px;
}
/* // for image file button margin */
.formInputImageUploadInput {
  margin-left: 20px;
}

/* // error text */
.errorText {
  color: var(--red);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -15px;
}
.errorText::before {
  /* display: inline; */
  content: '⚠  \00a0  ';
}

.loginFormButton {
  background: var(--brand);
  color: var(--white);
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginFormButton:hover {
  background: var(--brandHover);
  color: var(--brand);
}

@media (max-width: 799px) {
  .form {
    width: 60%;
    height: auto;
    margin: 10% 20%;
    border: 1px solid var(--white);
    background-color: var(--white);
    padding: 20px;
  }
  .formSecondary img {
    display: none;
  }
  .formBlog {
    width: 80%;
    height: auto;
    margin: 50px 10% 0 10%;
  }
}
@media (max-width: 1025px) {
  .formSecondary img {
    width: 30%;
  }

  .form {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 600px) {
  .formSecondary img {
    display: none;
  }
  .form {
    border: 1px solid var(--white);
    background-color: var(--white);
    padding: 20px;
  }
  .formInput {
    width: 100%;
  }
  .formBlog {
    width: 90%;
    margin: 5%;
  }
  .formSecondary {
    margin: 0;
  }
}
@media (max-width: 470px) {
  .form {
    border: 1px solid var(--white);
    background-color: var(--white);
    padding: 20px;
    margin: 0;
  }
  .formBlog {
    margin: 2.5%;
    width: 95%;
  }

  .formInput {
    width: 100%;
  }
  .formPrimary {
    height: 70vh;
    padding-bottom: 5rem;
  }
}

/* Change text in autofill textbox
input:-webkit-autofill {
  -webkit-text-fill-color: var(--white) !important;
  background-color: red !important;
  background: red !important;
} */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px var(--background) inset; /* Change the color to your own background color */
  -webkit-text-fill-color: var(--black);
}
