.packageMain {
  width: 100%;
  display: block;
  /* margin-top: 50px; */
  padding-top: 30px;
}

.packages {
  width: 88%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.pageState {
  width: 88%;
  margin: 0 auto;
}
