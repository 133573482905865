.stateViewContainer {
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-between;
}

.stateView {
  border-bottom: 2px solid var(--mainBlack);
  color: var(--mainBlack);
  text-align: center;
  width: calc(33% - 1em);
  font-size: 1.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stateView:not(:last-child)::before {
  display: block;
  content: ' \003E';
  
  height: 1em;
  width: 1em;
  line-height: 1em;
  border-radius: 100%;
  margin-bottom: 0.25rem;
  text-align: center;
  z-index: 1;
  font-size: 18px;
  border: 4px solid;
  padding: 4px;
  position: absolute;
  bottom: -20px;
  /* right: -30px; */
  right: calc(33% - (33% + 2em));
  color: var(--mainActive);
}
.stateViewSelected:not(:last-child)::before {
  color: var(--card);
}

.stateView:hover {
  cursor: pointer;
}

.stateViewSelected {
  border-bottom: 2px solid var(--card);
  color: var(--card);
}




@media (max-width: 550px) {
  .stateViewContainer {
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
     
  }
  .stateViewContainer>  *+*{
    
  margin-top: 50px;
     
  }
  .stateView {
    width: 100%;
    border-bottom: 0;
    justify-content: flex-start;
  }

  .stateView:not(:last-child)::before {
   top: 40px;
   bottom: 0;
   right: 0;
   left: 0;
   content: ' \25be';
   
  }
  
  
  
   
}


